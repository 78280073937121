import Vue from 'vue';
import VueHead from 'vue-head';
import VueScrollTo from 'vue-scrollto';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@/icons';
import App from './App.vue';
import './assets/tailwind.css';
import router from './router';

Vue.use(VueHead);
Vue.use(VueScrollTo);

Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('fa-layers', FontAwesomeLayers);
Vue.component('fa-layers-text', FontAwesomeLayersText);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');
