





















import Vue from 'vue';

export default Vue.extend({
  name: 'ContentSection',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
});
