












import Vue from 'vue';

export default Vue.extend({
  name: 'SectionLink',
  props: {
    section: {
      type: String,
      default: 'Products',
    },
  },
  computed: {
    navigationBarHidden() {
      return window.screen.width < 1024;
    },
  },
});
