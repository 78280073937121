<template>
  <div class="bg-snow font-sans text-indigo flex flex-col items-center text- text-lg md:text-2xl">

    <navigation-bar class="z-30" />

    <div
      id="intro"
      class="z-40 w-screen min-h-screen bg-mint flex flex-col items-center justify-between"
    >
      <a name="intro" />
      <div class="max-w-3xl w-full px-10">
        <img
          alt="Trackuity logo"
          src="@/assets/trackuity.svg"
          class="h-6 md:h-8 mt-10"
        >
        <p class="mt-10 md:mt-16">
          Hi, there.
        </p>
        <p class="mt-6">
          We’re Trackuity, the makers of item marketing platform Itemwise. Head over to
          <a
            class="underline font-medium"
            href="https://www.itemwise.com"
          >itemwise.com</a> if that’s what you’re looking for.
        </p>
        <p class="mt-6">
          More generally, we like to build tech that helps people keep track of things and easily
          take smart action. Improving their “tracking acuity” you could say — get it?
          <fa-icon :icon="['far', 'smile']" />
        </p>
      </div>
      <div class="flex flex-row items-center justify-center bouncing">
        <section-link class="p-6 sm:p-10">
          <fa-icon :icon="['far', 'chevron-down']" />
        </section-link>
      </div>
      <div class="
        w-full h-16 md:h-24 bg-indigo
        hidden sm:flex items-center justify-center
      ">
        <span class="text-mint text-xl md:text-4xl font-medium tracking-wide">
          Keep track
          <fa-icon :icon="['fal', 'abacus']" />
          and be smart
          <fa-icon :icon="['fal', 'head-side-brain']" />
        </span>
      </div>
    </div>

    <div class="lg:pt-32 pb-32">
      <content-section
        title="Products"
        icon="gift"
      >
        <content-paragraph
          :logo="require('@/assets/itemwise.svg')"
          link="https://itemwise.com"
        >
          Enables digital marketplaces to keep track of all of their items and leverage the right
          ones for smart marketing and monetization.
        </content-paragraph>
      </content-section>
      <content-section
        title="Team"
        icon="users"
      >
        <content-paragraph>
          We’re a small, lean-and-mean team of technology enthusiasts. We only hire when it hurts
          and put the bar way higher than the “no asshole rule”.
        </content-paragraph>
        <content-paragraph>
          Open-mindedness and self-thinking are cherished, busy work and long hours avoided. We’re
          in it for the long haul and want work to be an integrated part of our lives that fuels
          personal fulfillment.
        </content-paragraph>
      </content-section>
      <photo-line :src="require('@/assets/team-photo-line.png')" />
      <content-section
        title="Place"
        icon="location"
      >
        <content-paragraph>
          We’re based in Ghent, Belgium. Our headquarters are right in the city center, smack in
          the middle of all the lunch and after-work entertainment possibilities it provides.
        </content-paragraph>
        <content-paragraph>
          We’re also easy to reach by public transport and there are tons of options for living at
          walking or cycling distance from the office.
        </content-paragraph>
      </content-section>
      <photo-line :src="require('@/assets/place-photo-line.png')" />
      <content-section
        title="Jobs"
        icon="laptop"
      >
        <div class="flex flex-col">
          <div class="mt-8">
            To apply for any of the positions below, simply send an email
            to <a
              class="underline"
              href="mailto:jobs@trackuity.com"
            >jobs@trackuity.com</a> using
            the job title as subject.
          </div>
          <div class="flex flex-col gap-10 text-base my-10">
            <div
              class="flex flex-col gap-4"
              v-for="job in jobs"
              :key="job.title"
            >
              <h2 class="font-semibold text-xl">
                {{job.title}}
              </h2>
              <p>
                {{job.intro}}
              </p>
              <ul class="list-disc pl-6 flex flex-col gap-2">
                <li
                  v-for="bullet in job.bullets"
                  :key="bullet"
                >{{bullet}}</li>
              </ul>
              <p>
                {{job.outro}}
              </p>
            </div>
          </div>
          <div>
            Or you can send an open application to <a
              class="underline"
              href="mailto:jobs@trackuity.com"
            >jobs@trackuity.com</a> too, it won't go unnoticed!
          </div>
        </div>
      </content-section>
    </div>

    <div class="
      mb-10
      flex flex-col gap-8 items-center
      text-sm
    ">
      <div class="flex flex-row gap-2">
        <a
          v-for="social in [
            {icon: 'facebook-f', link: 'https://www.facebook.com/trackuity'},
            {icon: 'x-twitter', link: 'https://www.x.com/trackuity'},
            {icon: 'linkedin-in', link:'https://www.linkedin.com/company/trackuity'}
          ]"
          :key="social.icon"
          :href="social.link"
        >
          <fa-layers class="fa-fw text-3xl">
            <fa-icon
              icon="circle"
              class="text-3xl"
            />
            <fa-icon
              :icon="['fab', social.icon]"
              class="text-sm text-snow"
            />
          </fa-layers>
        </a>
      </div>
      <div class="px-4 text-center">
        TRACKUITY BV,
        VAT BE0549953277,
        Ridderstraat 15A,
        B-9000 Ghent,
        Belgium
      </div>
    </div>

  </div>
</template>

<script language="ts">
import Vue from 'vue';
import NavigationBar from '@/components/NavigationBar.vue';
import ContentSection from '@/components/ContentSection.vue';
import ContentParagraph from '@/components/ContentParagraph.vue';
import PhotoLine from '@/components/PhotoLine.vue';
import SectionLink from '@/components/SectionLink.vue';

const tagline = 'Keep track and be smart';
const description = 'The makers of item marketing platform Itemwise. We like to build tech that helps people keep track of things and easily take smart action.';

export default Vue.extend({
  name: 'Home',
  components: {
    NavigationBar,
    ContentSection,
    ContentParagraph,
    PhotoLine,
    SectionLink,
  },
  props: {
    section: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      jobs: [
        {
          title: 'Engineering Intern',
          intro: `
            We're offering engineering and computer science students the chance to develop 
            real-world skills in an environment that is ideal for maximal learning – a 
            lean-and-mean team of exceptional software engineers. The main requirements for this 
            role are:
          `,
          bullets: [
            'Fluent in Python and a healthy curiosity in data science and/or big data tech',
            'At least familiar with common web technologies, including HTML, CSS and Javascript',
            'Interested in data geekery and not limited by a lack of imagination',
            'Eager to tackle all kinds of problems and proud to work to a high standard',
            'Hands-on attitude and comfortable in a fast-moving startup environment',
          ],
          outro: `
            We can offer the environment, the expertise and the connections to
            jumpstart pursuing a career in software engineering. Who knows, you might even
            end up joining us as a full-blown employee!
          `,
        },
        {
          title: 'Marketing Intern',
          intro: `
            We're offering marketing students the chance to develop real-world skills in 
            an environment that is ideal for maximal learning – an ambitious and fast-paced 
            startup. In this role you'll:
          `,
          bullets: [
            'Drive the marketing and sales funnel',
            'Capture lead metrics and customer trends',
            'Research the market and help us with competitive analysis',
            'Prepare for and possibly attend meetings, events and conferences',
            'Expand to other duties as the need arises, in true startup fashion',
          ],
          outro: `
            We can offer the perfect environment for tons of learning and jumpstarting
            the pursuit of a fruitful marketing career. You might even end
            up joining us as a full-blown employee maybe!
          `,
        },
      ],
    };
  },
  head: {
    link: [
      {
        id: 'font-fira-sans',
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&display=swap',
      },
    ],
    meta: [
      { id: 'description', name: 'description', content: description },
      { id: 'og-title', name: 'og:title', content: tagline },
      { id: 'og-description', name: 'og:description', content: description },
      { id: 'og-url', name: 'og:url', content: 'https://www.trackuity.com' },
      { id: 'og-image', name: 'og:image', content: 'https://trackuity.github.io/preview/wide.png' },
      { id: 'og-type', name: 'og:type', content: 'site' },
      { id: 'twitter-card', name: 'twitter:card', content: 'summary' },
      { id: 'twitter-card', name: 'twitter:description', content: description },
      { id: 'twitter-title', name: 'twitter:title', content: tagline },
      { id: 'twitter-site', name: 'twitter:site', content: '@trackuity' },
      { id: 'twitter-image', name: 'twitter:image', content: 'https://trackuity.github.io/preview/square.png' },
    ],
  },
  computed: {
    navigationBarHidden() {
      return window.screen.width < 1024;
    },
  },
  mounted() {
    if (this.section) {
      this.$scrollTo(
        `#${this.section.toLowerCase()}`, 1000,
        {
          offset: window.screen.width < 1024 ? -48 : -128,
        },
      );
    }
  },
});
</script>

<style lang="postcss" scoped>
.bouncing {
  animation-name: bounce;
  animation-timing-function: ease;
  transform-origin: bottom;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  15% {
    transform: scale(0.9, 1.1) translateY(16px);
  }
  25% {
    transform: scale(1.1, 0.9) translateY(-16px);
  }
  40% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
</style>
