import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookF as fabFacebookF,
  faLinkedinIn as fabLinkedinIn,
  faXTwitter as fabXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import {
  faGift as fadGift,
  faUsers as fadUsers,
  faLocation as fadLocation,
  faLaptop as fadLaptop,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faAbacus as falAbacus,
  faHeadSideBrain as falHeadSideBrain,
} from '@fortawesome/pro-light-svg-icons';
import {
  faChevronDown as farChevronDown,
  faSmile as farSmile,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowRight as fasArrowRight,
  faCircle as fasCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
  FontAwesomeIcon as IconComponent,
  FontAwesomeLayers as LayersComponent,
  FontAwesomeLayersText as LayersTextComponent,
} from '@fortawesome/vue-fontawesome';

library.add(fabFacebookF as IconDefinition);
library.add(fabLinkedinIn as IconDefinition);
library.add(fabXTwitter as IconDefinition);

library.add(fadGift);
library.add(fadUsers);
library.add(fadLocation);
library.add(fadLaptop);

library.add(falAbacus);
library.add(falHeadSideBrain);

library.add(farChevronDown);
library.add(farSmile);

library.add(fasArrowRight);
library.add(fasCircle);

export const FontAwesomeIcon = IconComponent;
export const FontAwesomeLayers = LayersComponent;
export const FontAwesomeLayersText = LayersTextComponent;

export default library;
