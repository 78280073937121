
























import Vue from 'vue';
import SectionLink from '@/components/SectionLink.vue';

export default Vue.extend({
  name: 'NavigationBar',
  components: {
    SectionLink,
  },
});
