















import Vue from 'vue';

export default Vue.extend({
  name: 'ContentParagraph',
  props: {
    logo: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      default: '',
    },
  },
});
